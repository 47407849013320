import { Button, EButtonColorVariant } from '@outdoorsyco/bonfire';
import React, { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import Checkbox, {
  CheckboxAlignment,
  CheckboxSizes,
} from '@/components/switchback/Checkbox/Checkbox';
import { ALERT_OCTAGON } from '@/components/switchback/Icon/assets';
import TextInput from '@/components/switchback/TextInput';
import TermsLink from '@/components/ui/DisclaimerLinks/TermsLink';
import {
  emailValidator,
  nameValidator,
  phoneValidator,
  setPasswordValidator,
  termsValidator,
} from '@/constants/validationRules';
import { ISignupForm } from '@/services/types/auth/ISignupForm';
import { getClientLocale } from '@/utility/helpers';
import { logger } from '@/utility/logger';

import Notice, { NoticeType } from '../switchback/Notice/Notice';
import PrivacyLink from '../ui/DisclaimerLinks/PrivacyLink';
import { TFieldErrors } from './types/TFieldErrors';

export type IProps = {
  onSignup: (data: {
    name: string;
    email: string;
    phone: string;
    password: string;
    country?: string;
    token?: string;
    enableSms: boolean;
  }) => void;
  error: { error?: string } | string | null | undefined;
  reCaptchaToken: string;
  onDidAcceptTerms?: (termsState: boolean) => void;
};

const SignUpForm: React.FC<IProps> = ({ onSignup, error, reCaptchaToken, onDidAcceptTerms }) => {
  const intl = useIntl();
  const recaptchaRef: MutableRefObject<ReCAPTCHA | null> = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<ISignupForm & { terms: boolean }>({
    defaultValues: {
      enableSms: true,
    },
  });
  const didCheckTerms = watch('terms');
  const shouldUseRecaptcha = !!reCaptchaToken;

  useEffect(() => {
    onDidAcceptTerms?.(!!didCheckTerms);
  }, [didCheckTerms, onDidAcceptTerms]);

  const reset = () => {
    setIsSubmitting(false);
    recaptchaRef.current?.reset();
  };

  const fieldErrors: TFieldErrors<keyof ISignupForm | 'terms'> = {
    terms: {
      required: intl.formatMessage({
        defaultMessage: 'You must accept the Outdoorsy Terms of Service.',
        id: 'MGUg/m',
      }),
    },
    name: {
      generic: intl.formatMessage({
        defaultMessage: 'Full Name is required',
        id: '4OyuiC',
      }),
    },
    password: {
      required: intl.formatMessage({
        defaultMessage: 'Password is required.',
        id: 'pRvgsc',
      }),
      minLength: intl.formatMessage({
        defaultMessage: 'Password must be at least 8 characters.',
        id: '1JjZZ5',
      }),
      maxLength: intl.formatMessage({
        defaultMessage: 'Password must be at most 50 characters.',
        id: 'quRrnb',
      }),
      pattern: intl.formatMessage({
        defaultMessage: 'Password must contain at least one number.',
        id: 'dOPV08',
      }),
    },
    phone: {
      required: intl.formatMessage({
        defaultMessage: 'Phone number is required.',
        id: 'Il7p07',
      }),
      pattern: intl.formatMessage({
        defaultMessage: 'Please enter a valid phone number.',
        id: 'H+RqPP',
      }),
    },
    email: {
      required: intl.formatMessage({
        defaultMessage: 'Email is required',
        id: 'cU/aSG',
      }),
      pattern: intl.formatMessage({
        defaultMessage: 'Please enter a valid email address.',
        id: 'xs/MJu',
      }),
    },
    enableSms: {},
  };

  const messageInputEmail = intl.formatMessage({ defaultMessage: 'Email address', id: 'hJZwTS' });
  const messageInputName = intl.formatMessage({ defaultMessage: 'Name', id: 'HAlOn1' });
  const messageInputPassword = intl.formatMessage({ defaultMessage: 'Password', id: '5sg7KC' });
  const messageInputPhone = intl.formatMessage({ defaultMessage: 'Phone number', id: 'jdJhOL' });
  const messageInputSubmit = intl.formatMessage({
    defaultMessage: 'Create your Outdoorsy account',
    id: '4/bGKE',
  });

  const messageTagline = intl.formatMessage({
    defaultMessage: 'This is the start of something good.',
    id: 'Q91niO',
  });
  const messageConfirm = intl.formatMessage(
    {
      defaultMessage:
        'I certify that I’m at least 25 years old at the time of sign up and agree to the <terms>Outdoorsy Terms of Service</terms>.',
      id: 'XmuGpq',
    },
    { terms: label => <TermsLink>{label.toString()}</TermsLink> },
  );

  const messageConfirmPhoneNotifications = intl.formatMessage(
    {
      defaultMessage:
        'By checking this box, you agree to receive recurring automated promotional and personalized marketing text messages (e.g. cart reminders) from Outdoorsy at the cell number used when signing up. Consent is not a condition of any purchase. Reply HELP for help and STOP to cancel. Msg frequency varies. Msg and data rates may apply. View <terms>Terms</terms> & <privacy>Privacy</privacy>.',
      id: '5kJamP',
    },
    {
      terms: label => <TermsLink>{label.toString()}</TermsLink>,
      privacy: label => <PrivacyLink>{label.toString()}</PrivacyLink>,
    },
  );

  const onSubmit = useCallback(
    values => {
      setIsSubmitting(true);
      (async () => {
        try {
          const token = (shouldUseRecaptcha && (await recaptchaRef.current?.executeAsync())) || '';
          const locale = getClientLocale();
          await onSignup({
            name: values.name,
            email: values.email,
            phone: values.phone,
            password: values.password,
            country: locale?.country,
            token,
            enableSms: values.enableSms,
          });
        } catch (exception) {
          logger.error(exception);
        } finally {
          reset();
        }
      })();
    },
    [onSignup, shouldUseRecaptcha],
  );

  const nameErr = errors.name && fieldErrors.name.generic;
  const emailErr = errors.email && fieldErrors.email[errors.email.type];
  const phoneErr =
    errors.phone && (fieldErrors.phone[errors.phone.type] || fieldErrors.phone.pattern);
  const passErr = errors.password && fieldErrors.password[errors.password.type];
  const termsErr = errors.terms && fieldErrors.terms[errors.terms.type];

  return (
    <form
      className="text-sm space-y-6"
      aria-label={messageInputSubmit}
      onSubmit={handleSubmit(onSubmit)}
      noValidate>
      <p>{messageTagline}</p>
      {!!error && (
        <div className="flex items-center mb-2 text-red-800">
          <Notice icon={ALERT_OCTAGON} variant={NoticeType.critical}>
            {typeof error === 'string' ? error : error.error}
          </Notice>
        </div>
      )}
      <fieldset>
        <div className={nameErr ? 'mb-8' : 'mb-3'}>
          <TextInput
            id="name"
            type="text"
            autoComplete="name"
            label={messageInputName}
            aria-label={messageInputName}
            aria-invalid={!!errors.name}
            aria-required="true"
            aria-autocomplete="inline"
            aria-errormessage={nameErr}
            {...register('name', nameValidator)}
            error={nameErr}
          />
        </div>

        <div className={emailErr ? 'mb-8' : 'mb-3'}>
          <TextInput
            id="email"
            type="email"
            autoComplete="email"
            label={messageInputEmail}
            aria-label={messageInputEmail}
            aria-invalid={!!errors.email}
            aria-required="true"
            aria-autocomplete="inline"
            aria-errormessage={emailErr}
            {...register('email', emailValidator)}
            error={emailErr}
          />
        </div>
        <div className={phoneErr ? 'mb-8' : 'mb-3'}>
          <TextInput
            id="phone"
            type="tel"
            autoComplete="tel"
            label={messageInputPhone}
            aria-label={messageInputPhone}
            aria-invalid={!!errors.phone}
            aria-required="true"
            aria-autocomplete="inline"
            aria-errormessage={phoneErr}
            {...register('phone', phoneValidator)}
            error={phoneErr}
          />
        </div>
        <div className={passErr ? 'mb-8' : 'mb-3'}>
          <TextInput
            id="password"
            type="password"
            autoComplete="new-password"
            label={messageInputPassword}
            aria-label={messageInputPassword}
            aria-invalid={!!errors.password}
            aria-required="true"
            aria-autocomplete="list"
            aria-errormessage={passErr}
            {...register('password', setPasswordValidator)}
            error={passErr}
          />
        </div>
        <div className={passErr ? '' : 'mt-8'}>
          <Checkbox
            align={CheckboxAlignment.top}
            checkBoxSize={CheckboxSizes.large}
            className="-mt-5 autoType200 md:autoType300 md:-mt-2"
            id="terms"
            error={termsErr}
            {...register('terms', termsValidator)}
            disabled={isSubmitting}
            testId="terms-confirmation">
            <span>{messageConfirm}</span>
          </Checkbox>
        </div>
        <div className="mt-8">
          <Checkbox
            align={CheckboxAlignment.top}
            checkBoxSize={CheckboxSizes.large}
            className="-mt-5 autoType200 md:autoType300 md:-mt-2"
            id="enable-sms"
            disabled={isSubmitting}
            {...register('enableSms')}
            testId="terms-enable-sms-confirmation">
            <span>{messageConfirmPhoneNotifications}</span>
          </Checkbox>
        </div>
      </fieldset>
      <Button
        type="submit"
        data-testid="signup-submit-btn"
        aria-disabled="false"
        variant={EButtonColorVariant.Primary}
        label={messageInputSubmit}
        disabled={isSubmitting || !didCheckTerms}
        loading={isSubmitting}
        fullWidth
      />
      {shouldUseRecaptcha && (
        <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={reCaptchaToken} />
      )}
    </form>
  );
};

export { SignUpForm };
