import { createSelector } from 'reselect';

import { defaultLocale, TCurrency } from '@/config/locales';
import { TRootState } from '@/redux/rootReducer';
import { isCurrency, parseCurrencyCookie } from '@/utility/currency';
import { isPeaceOfMindInsuranceBundle } from '@/utility/insuranceBundle';

import { getLocale } from '../locale';

export const getBookingOrQuote = createSelector<
  TRootState,
  TRootState['checkout']['booking'],
  TRootState['quote']['data'],
  TRootState['checkout']['booking'] | TRootState['quote']['data']
>(
  state => state.checkout.booking,
  state => state.quote.data,
  (booking, quote) => booking || quote,
);

const getQuoteCurrency = createSelector<
  TRootState,
  TCurrency | undefined,
  TCurrency | undefined,
  TCurrency | undefined,
  ReturnType<typeof getLocale>,
  TCurrency
>(
  state => state.quote.data?.presentment_currency,
  state => parseCurrencyCookie(state.cookies?.userLocaleCurrency),
  state => {
    const currency = state.auth.user?.currency;
    if (isCurrency(currency)) return currency;
  },
  getLocale,
  (quoteCurrency, cookiesCurrency, userCurrency, locale) => {
    const localeCurrency = locale.base_currency;
    const defaultCurrency = defaultLocale.base_currency;

    return quoteCurrency || userCurrency || cookiesCurrency || localeCurrency || defaultCurrency;
  },
);

const getBookingCurrency = createSelector<
  TRootState,
  TRootState['checkout']['booking'],
  TRootState['booking'],
  TCurrency | undefined
>(
  state => state.checkout.booking,
  state => state.booking,
  (checkoutBooking, booking) => {
    return checkoutBooking?.presentment_currency || booking?.details.data?.presentment_currency;
  },
);

export const getBookingOrQuoteCurrency = createSelector<
  TRootState,
  ReturnType<typeof getBookingCurrency>,
  ReturnType<typeof getQuoteCurrency>,
  TCurrency
>(
  getBookingCurrency,
  getQuoteCurrency,
  (bookingCurrency, quoteCurrency) => bookingCurrency || quoteCurrency,
);

export const getHasBookingChangedCurrency = createSelector<
  TRootState,
  ReturnType<typeof getBookingCurrency>,
  ReturnType<typeof getQuoteCurrency>,
  boolean
>(
  getBookingCurrency,
  getQuoteCurrency,
  (bookingCurrency, quoteCurrency) => bookingCurrency !== quoteCurrency,
);

export const getBookingOrQuoteLocale = (state: TRootState) => {
  return state.checkout.booking?.locale || state.listing.data?.locale || getLocale(state);
};

export const isPeaceOfMindSelectedSelector = createSelector<
  TRootState,
  ReturnType<typeof getBookingOrQuote>,
  boolean
>(getBookingOrQuote, bookingOrQuote => {
  return isPeaceOfMindInsuranceBundle(bookingOrQuote?.insurance_bundle);
});

export const getBookingOrQuoteStationaryDelivery = createSelector<
  TRootState,
  ReturnType<typeof getBookingOrQuote>,
  boolean
>(getBookingOrQuote, bookingOrQuote => {
  return !!bookingOrQuote?.delivery?.stationary;
});

export const getBookingOrQuoteIsLoading = createSelector<
  TRootState,
  TRootState['checkout'],
  TRootState['quote'],
  boolean
>(
  state => state.checkout,
  state => state.quote,
  (checkout, quote) => {
    if (checkout.booking) {
      return checkout.isLoading;
    }

    return quote.isLoadingQuote;
  },
);
