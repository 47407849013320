import { clsx } from 'clsx';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Button, { ButtonSize, ButtonVariants } from '@/components/switchback/Button/Button';
import { IHeaderSearchFilterGuests } from '@/redux/modules/globalHeader';

interface IDateTab {
  onClick: () => void;
  selectedDates: string | null;
  isCampgroundSearch?: boolean;
}

export const DateTab: React.FC<IDateTab> = ({ onClick, selectedDates, isCampgroundSearch }) => (
  <button
    type="button"
    onClick={onClick}
    className={clsx(
      'flex items-center justify-center px-4 text-base border-l cursor-pointer border-neutral-20 text-secondary-base semiHighlight',
      { ['!border-l-0 md:w-48 md:!justify-start md:!pl-6']: isCampgroundSearch },
    )}>
    {selectedDates ? (
      <span className="font-medium text-primary-base">{selectedDates}</span>
    ) : (
      <span className="font-medium text-gray-800">
        <FormattedMessage defaultMessage="Dates" id="D6b0Au" />
      </span>
    )}
  </button>
);

interface IGuestTabProps {
  onClick: () => void;
  guests?: IHeaderSearchFilterGuests;
  isCampgroundSearch?: boolean;
  isGuestOccupancyAvailable?: boolean;
}

export const GuestsTab: React.FC<IGuestTabProps> = ({
  onClick,
  guests,
  isCampgroundSearch,
  isGuestOccupancyAvailable,
}) => {
  const intl = useIntl();

  const totalGuestValue =
    (guests?.adults || 0) +
    (guests?.children || 0) +
    (isGuestOccupancyAvailable ? guests?.infants || 0 : 0);
  const totalGuests = totalGuestValue || 0;

  const buttonLabel = intl.formatMessage(
    {
      defaultMessage: 'Click to {hasValue, select, true {change the} other {add}} guests',
      id: 'PB9EPa',
      description: 'Global Header > Search',
    },
    {
      hasValue: !!totalGuests,
    },
  );
  const placeholder = intl.formatMessage({
    defaultMessage: 'Guests',
    id: 'ogcDqT',
    description: 'Global Header > Placeholder text for header details field',
  });
  const detailsValueText = intl.formatMessage(
    {
      defaultMessage: '{totalGuests, plural, one {# guest} other {# guests}}',
      id: 'YstT2S',
      description: 'Placeholder for header guests field',
    },
    {
      totalGuests,
    },
  );

  return (
    <button
      type="button"
      onClick={onClick}
      className={clsx(
        'flex items-center justify-center px-4 text-base border-l cursor-pointer border-neutral-20 text-secondary-base semiHighlight',
        { ['md:w-48 md:!justify-start']: isCampgroundSearch },
      )}
      aria-label={buttonLabel}>
      {!totalGuests ? (
        <span className="font-medium text-gray-800">{placeholder}</span>
      ) : (
        <span className="font-medium text-primary-base">{detailsValueText}</span>
      )}
    </button>
  );
};

interface ISearchButtonTabProps {
  loading: boolean;
}

export const SearchButton: React.FC<ISearchButtonTabProps> = ({ loading }) => (
  <div>
    <Button
      type="submit"
      testid="search-submit-btn"
      className="flex items-center rounded-full h-[46px]"
      size={ButtonSize.normal}
      loading={loading}
      variant={ButtonVariants.darkPrimaryContained}>
      <span className="text-500">
        <FormattedMessage defaultMessage="Search" id="xmcVZ0" />
      </span>
    </Button>
  </div>
);
