import {
  Button,
  EButtonColorVariant,
  ELinkColorVariant,
  ETextStyleVariant,
  Link,
  Text,
} from '@outdoorsyco/bonfire';
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Heading from '@/components/switchback/Heading/Heading';
import CustomLink from '@/components/switchback/Link/Link';
import { getAllowedStatesForStay } from '@/redux/selectors/stayStates';

import css from './UniversalSearchStayBanner.module.css';

interface IUniversalSearchStayBannerProps {
  isStayFullyEnabled: boolean;
}

const UniversalSearchStayBanner: React.FC<IUniversalSearchStayBannerProps> = ({
  isStayFullyEnabled = false,
}) => {
  const intl = useIntl();
  const title1 = intl.formatMessage({
    defaultMessage: 'Introducing',
    id: '6DsOMQ',
    description: 'Introducing Stays',
  });
  const title2 = intl.formatMessage({
    defaultMessage: 'Stays',
    id: 'd6w4vS',
    description: 'Introducing Stays',
  });
  const subtitle = intl.formatMessage({
    defaultMessage:
      'Introducing Stays: Now you can stay in a treehouse, yurt, or cabin. Plan your adventure today.',
    id: 'RMoxGM',
    description: 'Introducing Stays',
  });
  const allowedStatesForStay = useSelector(getAllowedStatesForStay);

  const addressInDescription = useMemo(() => {
    const stateNames = allowedStatesForStay?.map(state => state.full_name);

    if (stateNames.length === 1) {
      return intl.formatMessage(
        {
          defaultMessage: 'in {stateName}',
          id: '3VDrH4',
        },
        { stateName: stateNames[0] || 'California' },
      );
    } else if (stateNames.length <= 3 && stateNames.length > 1) {
      const statesString = stateNames.slice(0, -1).join(', ') + ' or ' + stateNames.slice(-1);
      return intl.formatMessage(
        {
          defaultMessage: 'in {statesString}',
          id: '5rpVoB',
        },
        { statesString },
      );
    } else {
      return intl.formatMessage({
        defaultMessage: 'with limited availability',
        id: 'OzULVl',
      });
    }
  }, [allowedStatesForStay, intl]);

  const rawHref = `/rv-search?filter%5Brental_category%5D=stay&address=${
    allowedStatesForStay.length === 1 ? 'California' : 'United States'
  }`;

  return isStayFullyEnabled ? (
    <div className={css.container}>
      <div className={css.content}>
        <p>{subtitle}</p>
      </div>
    </div>
  ) : (
    <div className={css.container}>
      <Heading level={2} className={css.title}>
        {title1} <em>{title2}</em>
      </Heading>
      <div className={css.content}>
        <p>
          {intl.formatMessage(
            {
              defaultMessage: `Now you can stay in a treehouse, yurt, or cabin {addressInDescription}. Plan your adventure today.`,
              id: 'aK5d36',
            },
            { addressInDescription },
          )}
        </p>
        <div>
          <CustomLink href={rawHref} legacy forceClientsideRouting passHref>
            <Button
              className="w-full transition-none md:w-auto md:inline-block"
              variant={EButtonColorVariant.Primary}
              label={intl.formatMessage({ defaultMessage: 'Explore stays', id: 'iOp0yS' })}
            />
          </CustomLink>
        </div>
      </div>
      <div className="flex justify-center mt-4">
        <Text variant={ETextStyleVariant.SmallRegular}>
          <FormattedMessage
            defaultMessage="Interested in listing your own outdoor stay? "
            id="pSnQl5"
          />
        </Text>
        &nbsp;
        <Link href={'/list-your-stay?utm_source=usm'} colorVariant={ELinkColorVariant.Tertiary}>
          <Text variant={ETextStyleVariant.SmallRegular}>
            <FormattedMessage defaultMessage="Get started today!" id="/CL/wI" />
          </Text>
        </Link>
      </div>
    </div>
  );
};

export default UniversalSearchStayBanner;
